/* -----------------------------------------------------------------------------
    IMPORT SETTINGS
----------------------------------------------------------------------------- */
@import '__assets/css/settings';

/* -----------------------------------------------------------------------------
    COMPONENT CSS
----------------------------------------------------------------------------- */

.avatar {
    background-color: $color-dark-blue;
    padding-top: 47px;
    padding-bottom: 47px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.3);

    &__info-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 40px;
        padding-right: 20px;

        &__avatar-name {
            text-transform: uppercase;
        }

        &__profile-container {
            display: flex;
            flex-direction: column;

            &__update {
                text-decoration: underline;
                text-underline-position: under;
                color: $color-pale-blue;
                font-size: 13px;
                line-height: 18px;

                &:hover {
                    opacity: 0.85;
                }
            }
        }
    }
}
