/* -----------------------------------------------------------------------------
    IMPORT SETTINGS
----------------------------------------------------------------------------- */

@import "__assets/css/settings";

/* -----------------------------------------------------------------------------
    LeftMenu CSS
----------------------------------------------------------------------------- */

.left-menu {
	color: $color-white;
	width: 300px;
	z-index: 2;
	position: fixed;
	top: 100px;
	height: calc(100vh - 100px);
	position: fixed;

	@include mq(medium) {
		right: 0;
		width: 375px;
		transform: translateX(100%);
		transition: all 0.5s ease;
	}

	@include mq(small) {
		width: 100%;
		top: 80px;
		height: calc(100vh - 80px);
	}

	// let the left menu come from the right hand side
	&--open {
		transform: translateX(0);
	}

	&__wrapper {
		position: relative;
		overflow-y: auto;
		height: 100%;
		display: flex;
		flex-direction: column;
		background-color: $color-dark-blue;

		@include mq(medium) {
			background-color: $color-martinique;
		}
	}

	&__close-icon {
		position: absolute;
		right: 6px;
		top: 6px;
		width: 20px;
		height: 20px;
		margin: 15px 15px;
		display: none;
		cursor: pointer;

		@include mq(medium) {
			display: block;
		}
	}

	&__menu-items {
		background-color: $color-dark-blue;
		padding: 20px 0 20px 0;
	}

	&__top-menu-items {
		background-color: $color-martinique;
		padding: 10px 0 20px 0;

		@include mq(small) {
			padding-bottom: 130px;
		}
	}

	&__sign-out-button {
		margin: 20px;

		a {
			text-decoration: none;
		}

		@include mq(small) {
			margin-bottom: 5px;
		}
	}
}
