/* -----------------------------------------------------------------------------z
    HTML style
----------------------------------------------------------------------------- */
html {
	font-family: $base-font-family;
	font-size: $base-font-size;
	background: #e4ebf1;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	--antd-wave-shadow-color: #4638d0 !important;

	@include mq(small) {
		background: #fff;
	}
}

/* -----------------------------------------------------------------------------z
    Main style
----------------------------------------------------------------------------- */

.main {
	min-height: calc(100vh - 100px);

	.page {
		display: flex;
		position: relative;
		min-height: calc(100vh); // 100px for header
		padding-top: 100px; // header height

		@include mq(small) {
			padding-top: 80px;
		}

		&__logo {
			height: 30px;
		}

		@include mq(medium) {
			flex-flow: row wrap;
			min-height: auto;
			padding-left: 0;
		}
	}

	.content {
		flex: 1;
		order: 2;
		padding-left: 300px; // the same as left menu width
		width: 100%;
		background-color: $color-pale-blue;

		@include mq(medium) {
			padding-left: 0;
		}
	}

	.menu {
		flex: 1;
	}

	.footer {
		order: 4;
	}

	@include mq(medium) {
		min-height: calc(100vh - 207px);
	}

	@include mq(small) {
		min-height: calc(100vh - 226px);
	}
}

/* -----------------------------------------------------------------------------z
    Main wrapper style
----------------------------------------------------------------------------- */
.main-wrapper {
	background-color: $color-pale-blue;
	height: 100%;
	padding: 20px;

	@include mq(medium) {
		min-height: calc(100vh - 100px);
		padding: 20px;
	}
	@include mq(small) {
		min-height: calc(100vh - 80px);
		padding: 20px;
	}
}

/* -----------------------------------------------------------------------------z
    Main container style.
    This dynamic main container is used on pages except dashboard.
----------------------------------------------------------------------------- */
.main-container {
	background-color: $color-white;
	height: 100%;
	padding: 30px;
	width: 100%;
	transition: width 0.5s ease;

	@include mq(small) {
		padding: 30px 20px;
	}
}

.right-menu-open .main-container {
	// width: calc(100% - 406px);
	// transition: width 0.5s ease;

	@include mq(medium) {
		width: 100%;
	}
}

/* -----------------------------------------------------------------------------z
    Content container style.
----------------------------------------------------------------------------- */
.content-container {
	width: 70%;
	min-width: 477px;

	@include mq(medium-large) {
		width: 100%;
		min-width: auto;
	}
}

/* -----------------------------------------------------------------------------z
    Page title style
----------------------------------------------------------------------------- */
.page-title {
	@include font-size(14px, 14px);
	font-weight: bold;
	color: $color-arapawa;
	margin-bottom: 48px;

	@include mq(medium) {
		margin-bottom: 40px;
	}

	@include mq(small) {
		margin-bottom: 30px;
	}
}

/* -----------------------------------------------------------------------------
     SCROLL BAR
----------------------------------------------------------------------------- */

::-webkit-scrollbar {
	width: 8px;
	z-index: 1;
}

::-webkit-scrollbar-track {
	background: none;
}

::-webkit-scrollbar-thumb {
	position: absolute;
	background: rgba(0, 0, 0, 0.4);
	cursor: pointer;
	user-select: none;
}

.rc-virtual-list-scrollbar-thumb {
	border-radius: 0 !important;
	background: rgba(0, 0, 0, 0.4) !important;
}
