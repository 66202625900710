/* -----------------------------------------------------------------------------
    IMPORT SETTINGS
----------------------------------------------------------------------------- */

@import "__assets/css/settings";

/* -----------------------------------------------------------------------------
    COMPONENT CSS
----------------------------------------------------------------------------- */

.app-button {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	cursor: pointer;
	width: 100%;

	&--large {
		font-size: $big-button-font-size;
		font-weight: bold;
		line-height: $big-button-line-height;
		padding: 10px;
	}

	&--middle {
		font-size: $small-button-font-size;
		font-weight: normal;
		line-height: $small-button-line-height;
		padding: 6px;
	}

	&--small {
		font-size: $small-button-font-size;
		font-weight: normal;
		line-height: $tiny-button-line-height;
		padding: 6px;
	}

	&--primary {
		background-color: $button-blue-background-color;
		color: $color-white;

		&:hover {
			background-color: $button-blue-hover-color;
		}
	}

	&--secondary {
		border: 1px solid $button-blue-background-color;
		color: $button-blue-background-color;

		&:hover {
			background-color: $button-blue-background-color;
			color: #fff;
		}
	}

	&--green {
		background-color: #1bafaf;
		color: $color-white;

		&:hover {
			opacity: 0.5;
		}
	}

	&--workflowGreen {
		background-color: rgb(0, 180, 97);
		color: $color-white;

		&:hover {
			color: rgb(0, 180, 97);
			background-color: #fff;
			border: 1px solid rgb(0, 180, 97);
		}
	}
}
