/* -----------------------------------------------------------------------------
    IMPORT SETTINGS
----------------------------------------------------------------------------- */

@import '__assets/css/settings';

/* -----------------------------------------------------------------------------
    TOPBAR MENU ITEM CSS
----------------------------------------------------------------------------- */

.top-bar-menu-item {
	text-decoration: none;
	padding-right: 25px;
	padding-left: 25px;
	display: flex;
	align-items: center;

	span {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
	}

	&__text {
		position: relative;
		color: $color-smoky;
		font-size: 14px;
		font-weight: bold;
		line-height: 18px;
		text-decoration: none;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			transform: scaleX(0);
			height: 4px;
			background-color: $color-smoky;
			transition: transform 0.2s;
		}
	}

	&:hover,
	&--active {
		.top-bar-menu-item__text:before {
			transform: scaleX(1);
		}
	}
}
