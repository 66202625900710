/* -----------------------------------------------------------------------------
    IMPORT SETTINGS
----------------------------------------------------------------------------- */
@import '../../__assets/css/settings';

@keyframes loader {
    0% {
        background-position: -400px 0;
    }
    100% {
        background-position: 400px 0;
    }
}

.simple-loader {
    &__title {
        @include font-size(15px, 15px);
        font-weight: 600;
        color: $color-light-purple3;
        margin-bottom: 30px;
    }

    &__section {
        margin: 30px 0;
    }

    .animated-bg {
        height: 25px;
        margin-bottom: 15px;
        position: relative;
        background-color: $loader-color;
        border-radius: $loader-border-radius;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: loader;
        animation-timing-function: linear;
        background: linear-gradient(90deg, #f0f5fc 8%, #dce7f7 18%, #f0f5fc 33%);
        background-size: 800px 70px;

        &--long {
            width: 100%;
        }

        &--short {
            width: 40%;
        }
    }

    &--dark-mode .animated-bg {
        background-color: darken($loader-color, 10%);
        background-image: linear-gradient(
            90deg,
            darken(#f0f5fc, 10%) 8%,
            darken(#dce7f7, 10%) 18%,
            darken(#f0f5fc, 10%) 33%
        );
    }
}
