/* -----------------------------------------------------------------------------
    IMPORT SETTINGS
----------------------------------------------------------------------------- */
@import '__assets/css/settings';

/* -----------------------------------------------------------------------------
    TOUR CSS
----------------------------------------------------------------------------- */

.tour {
    &__main-title {
        text-align: left;
        color: $color-arapawa;
        font-size: 20px;
        padding: 4px 0;
    }

    &__main-desc {
        padding-top: 2px;
        padding-bottom: 6px;
    }

    &__main-point:before {
        content: '\2771';
        position: relative;
        top: 0px;
        left: -8px;
        color: $color-arapawa;
    }

    &__main-point {
        line-height: 18px;
        text-align: left;
        margin-left: 15px;
        margin-top: 10px;
        margin-bottom: 0px;
        text-indent: -6px;

        &__highlight {
            font-weight: 500;
            color: $color-arapawa;
        }
    }

    &__main-footer {
        margin-top: 1px;
    }
}
