/* -----------------------------------------------------------------------------
    IMPORT SETTINGS
----------------------------------------------------------------------------- */
@import '__assets/css/settings';

/* -----------------------------------------------------------------------------
    FOOTER CSS
----------------------------------------------------------------------------- */

.footer {
    padding: 27px 40px;
    background-color: $color-dark-blue;
    margin-top: auto;

    @include mq(medium) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex: 1 100%;
    }

    @include mq(small) {
        flex-direction: column;
    }

    &__text_container {
        width: 100%;

        @include mq(medium) {
            width: 219px;
        }

        @include mq(small) {
            width: 100%;
        }
    }

    &__text {
        font-size: 11px;
        line-height: 17px;
        color: $color-kimberly;
    }

    &__links {
        @extend .footer__text;
        margin-top: 10px;
        text-decoration: underline;
        text-underline-position: under;

        &:hover {
            opacity: 0.85;
        }
    }

    &__terms:after {
        content: '|';
        font-size: 12px;
        padding-left: 10px;
        font-weight: bold;
        display: inline-block;
    }

    &__privacy {
        padding-left: 10px;
    }

    &__sign-out-button {
        margin-bottom: 40px;
        width: 100%;
        order: -1;

        a {
            text-decoration: none;
        }

        @include mq(medium) {
            order: 1;
            padding: 0;
            width: 220px;
            margin-bottom: 0;
        }

        @include mq(small) {
            order: 0;
            padding: 0;
            width: 100%;
            margin-bottom: 15px;
        }
    }
}
