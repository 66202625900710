/* -----------------------------------------------------------------------------
    IMPORT SETTINGS
----------------------------------------------------------------------------- */

@import '__assets/css/settings';

/* -----------------------------------------------------------------------------
    EMPTY DATA CSS
----------------------------------------------------------------------------- */

.empty-data {
    display: flex;
    flex-direction: column;
    margin: 20px;

    &__header-text {
        font-size: 14px;
        font-weight: bold;
        line-height: 30px;
        color: $color-arapawa;
    }

    &__icon {
        margin: 40px 0 0 0;
        height: 127px;
        align-self: center;

        @include mq(small) {
            margin: 0 0 0 0;
            height: 75px;
        }
    }

    &__nodata-text {
        margin-top: 25px;
        font-size: 14px;
        font-weight: normal;
        line-height: 30px;
        text-align: center;
        color: $color-arapawa;

        @include mq(small) {
            margin-top: 8px;
        }
    }

    &__notification-text {
        margin-top: 5px;
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;
        text-align: center;
        color: $color-arapawa;
    }
}
