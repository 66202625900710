/* -----------------------------------------------------------------------------
    IMPORT SETTINGS
----------------------------------------------------------------------------- */

@import '__assets/css/settings';

/* -----------------------------------------------------------------------------
    OVERLAY CSS
----------------------------------------------------------------------------- */

.overlay {
    position: fixed;
    top: 0;
    width: 0;
    height: 0;
    background-color: $color-black;
    opacity: 0;
    transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;

    &--show {
        visibility: visible;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        transition: opacity 0.5s;
        z-index: 1;
    }
}
