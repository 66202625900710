/**
* A thin layer on top of normalize.css that provides a starting point more
* suitable for web applications.
*/

/**
* Prevent margin and border from affecting element width.
* https://goo.gl/pYtbK7
*
*/

html {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

/**
* Removes the default spacing and border for appropriate elements.
*/

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
p,
pre {
	margin: 0;
}

button {
	background: transparent;
	border: 0;
	padding: 0;
}

/**
* Work around a Firefox/IE bug where the transparent `button` background
* results in a loss of the default `button` focus styles.
*/

button:focus {
	outline: 1px dotted;
	outline: 1px auto $button-blue-background-color;
}

fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

iframe {
	border: 0;
}

ol,
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

/**
* Suppress the focus outline on elements that cannot be accessed via keyboard.
* This prevents an unwanted focus outline from appearing around elements that
* might still respond to pointer events.
*/

[tabindex="-1"]:focus {
	outline: none !important;
}

/**
* Remove the gap between images and the bottom of their containers. Auto resize
* image to prevent overflow.
*/

img {
	vertical-align: middle;
	max-width: 100%;
}
